<template>
  <!--  product_amount-->
  <v-data-table
    :headers="headers"
    :items="products"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :item-class="row_classes"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>

        <v-btn
          v-if="selected && selected.length > 0"
          class="danger-badge ml-3"
          @click="deleteSelected"
        >
          ลบ
        </v-btn>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="850"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <div class="d-flex justify-space-between"> -->
            <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="close"
            >
              เพิ่มสินค้า
            </v-btn>
            <!-- </div> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-row>
                      <v-col
                        v-if="editedItem.image_path"
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-img
                          :src="editedItem.image_path"
                        ></v-img>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-file-input
                          v-model="editedItem.file"
                          label="รูปสินค้า"
                        ></v-file-input>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.product_code"
                          label="รหัสสินค้า"
                          placeholder="รหัสสินค้า"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.product_name"
                          label="ชื่อสินค้า"
                          placeholder="ชื่อสินค้า"
                        ></v-text-field>
                        <small
                          v-for="(err,index) in v$.editedItem.product_name.$silentErrors"
                          v-if="v$.editedItem.product_name.$error"
                          class="validate-err"
                        >{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-radio-group
                          v-model="editedItem.type_product"
                          label="ประเภทสินค้าคงคลัง"
                        >
                          <v-radio
                            value="1"
                            label="สินค้าคงคลัง(ออนไลน์)"
                          />
                          <v-radio
                            value="2"
                            label="สินค้าคงคลัง(ภายใน)"
                          />
                        </v-radio-group>
                      </v-col>

                      <v-col
                        v-if="!editedItem.product_id"
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-checkbox
                          v-model="editedItem.duplicate"
                          hide-details
                          dense
                          value="1"
                          label="สร้างทั้ง 2 ประเภท สินค้าคงคลัง"
                        ></v-checkbox>
                      </v-col>

                      <v-col
                        v-if="!editedItem.product_id"
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.amount"
                          type="number"
                          label="จำนวนสินค้า"
                          placeholder="จำนวนสินค้า"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-select
                          v-model="editedItem.product_type_id"
                          label="ประเภทสินค้า"
                          :items="product_types"
                          item-text="product_type_name"
                          item-value="product_type_id"
                        ></v-select>
                        <small
                          v-for="(err,index) in v$.editedItem.product_type_id.$silentErrors"
                          v-if="v$.editedItem.product_type_id.$error"
                          class="validate-err"
                        >{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-select
                          v-model="editedItem.phone_brand_id"
                          label="ยี่ห้อโทรศัพท์"
                          :items="phone_brands"
                          item-text="phone_brand_name"
                          item-value="phone_brand_id"
                        ></v-select>
                        <small
                          v-for="(err,index) in v$.editedItem.phone_brand_id.$silentErrors"
                          v-if="v$.editedItem.phone_brand_id.$error"
                          class="validate-err"
                        >{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-select
                          v-model="editedItem.phone_model_id"
                          label="รุ่นโทรศัพท์"
                          :items="phone_models"
                          item-text="phone_model_name"
                          item-value="phone_model_id"
                          placeholder="รุ่นโทรศัพท์"
                          outlined
                          dense
                          hide-details
                          class="mt-2"
                        >
                          <template v-slot:prepend-item>
                            <v-list-item>
                              <v-list-item-content>
                                <v-text-field
                                  v-model="st"
                                  placeholder="Search"
                                  @input="getPhoneModel"
                                ></v-text-field>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-select>
                        <small
                          v-for="(err,index) in v$.editedItem.phone_model_id.$silentErrors"
                          v-if="v$.editedItem.phone_model_id.$error"
                          class="validate-err"
                        >{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.product_weight"
                          type="number"
                          min="0"
                          label="น้ำหนักสินค้า"
                          placeholder="น้ำหนักสินค้า (กรัม)"
                        ></v-text-field>
                        <small
                          v-for="(err,index) in v$.editedItem.product_weight.$silentErrors"
                          v-if="v$.editedItem.product_weight.$error"
                          class="validate-err"
                        >{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.product_costs"
                          type="number"
                          min="0"
                          label="ต้นทุน"
                          placeholder="ต้นทุน (บาท)"
                        ></v-text-field>
                        <small
                          v-for="(err,index) in v$.editedItem.product_costs.$silentErrors"
                          v-if="v$.editedItem.product_costs.$error"
                          class="validate-err"
                        >{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.product_price1"
                          label="ค้าส่ง"
                          placeholder="ค้าส่ง"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.product_price2"
                          type="number"
                          label="ค้าปลีก"
                          placeholder="ค้าปลีก"
                        ></v-text-field>
                      </v-col>

                      <!--                      <v-col cols="12" sm="12" md="12">-->
                      <!--                        <v-text-field-->
                      <!--                          type="number"-->
                      <!--                          v-model="editedItem.product_price3"-->
                      <!--                          label="ตัวแทนพิเศษ"-->
                      <!--                          placeholder="ตัวแทนพิเศษ"-->
                      <!--                        ></v-text-field>-->
                      <!--                      </v-col>-->
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-textarea
                          v-model="editedItem.product_detail"
                          label="รายละเอียด"
                          placeholder="รายละเอียด"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>

                  <!--                  <v-col v-if="editedItem.product_code" cols="6" sm="6" md="6" class="text-center">-->
                  <!--                    <v-card class="text-center">-->
                  <!--                      <v-card-title style="background-color: #0a6aa1;">-->
                  <!--                        <label style="text-align: center;width: 100%;font-weight: bold;color: white"-->
                  <!--                               for="" class="text-center">QR Code</label>-->
                  <!--                      </v-card-title>-->
                  <!--                      <v-card-text>-->
                  <!--                        <qr-code-->
                  <!--                          style="margin: 30px auto;width: 255px;"-->
                  <!--                          :text="editedItem.product_code"></qr-code>-->
                  <!--                      </v-card-text>-->
                  <!--                      <v-card-title>-->
                  <!--                        <h3 style="text-align: center;width: 100%;font-weight: bold"-->
                  <!--                            class="text-center">-->
                  <!--                          {{ editedItem.product_code }}-->
                  <!--                        </h3>-->
                  <!--                      </v-card-title>-->
                  <!--                    </v-card>-->
                  <!--                  </v-col>-->
                  <!--                  <v-col v-if="editedItem.product_code" cols="6" sm="6" md="6" class="text-center">-->
                  <!--                    <v-col cols="12" sm="12" md="12">-->
                  <!--                      <v-text-field-->
                  <!--                        v-model="size"-->
                  <!--                        type="number"-->
                  <!--                        label="ขนาด (กว้าง/สูง)"-->
                  <!--                        placeholder="ความกว้าง/สูง"-->
                  <!--                      ></v-text-field>-->
                  <!--                    </v-col>-->
                  <!--                    <v-col cols="12" sm="12" md="12">-->
                  <!--                      <v-text-field-->
                  <!--                        v-model="count"-->
                  <!--                        type="number"-->
                  <!--                        label="จำนวน"-->
                  <!--                        placeholder="จำนวน"-->
                  <!--                      ></v-text-field>-->
                  <!--                    </v-col>-->
                  <!--                    <v-col cols="12" sm="12" md="12">-->
                  <!--                      <v-btn-->
                  <!--                        @click="printQR"-->
                  <!--                        style="float: right"-->
                  <!--                        color="primary">ปริ้น QR Code</v-btn>-->
                  <!--                    </v-col>-->
                  <!--                  </v-col>-->
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-checkbox
        v-model="item.product_status"
        value="Y"
        dense
        hide-details
        @click="UpdateStatus(item.product_id)"
      >
      </v-checkbox>
    </template>
    <template v-slot:item.image="{ item }">
      <v-img
        width="80"
        :src="item.image_path"
      ></v-img>
    </template>
    <template v-slot:item.select="{ item }">
      <v-checkbox
        :id="'product_'+item.product_id"
        v-model="selected"
        :value="item.product_id"
      >
      </v-checkbox>
    </template>
    <template v-slot:item.view="{ item }">
      <router-link
        style="text-decoration: none"
        :to="{name : 'pages-product-detail',params : { id : item.product_id ,type : 'abc' }}"
      >
        <v-btn
          color="secondary"
        >
          ดูรายการปรับยอดสินค้า
        </v-btn>
      </router-link>
      <!--      <v-icon-->
      <!--        small-->
      <!--        class="mr-2"-->
      <!--        @click="editItem(item)"-->
      <!--      >-->
      <!--        {{ icons.mdiPencil }}-->
      <!--      </v-icon>-->
      <!--      <v-icon-->
      <!--        small-->
      <!--        @click="deleteItem(item)"-->
      <!--      >-->
      <!--        {{ icons.mdiDelete }}-->
      <!--      </v-icon>-->
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>
    <template v-slot:item.promotion_free="{ item }">
      <span v-if="item.start_promotion && item.end_promotion">
        วันที่เริ่ม {{ item.start_promotion ? item.start_promotion : '-' }} <br>
        วันที่สิ้นสุด {{ item.end_promotion ? item.end_promotion : '-' }}
      </span>
      <span v-if="!item.start_promotion && !item.end_promotion">
        -
      </span>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'
import DatePicker from 'vue2-datepicker'

// import config from '../../../config'
import Swal from 'sweetalert2'
import { useVuelidate } from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import instance from '../../../axios.service'
import instance_product from '../../../services/product'
import instance_product_type from '../../../services/productType'
import instance_phone_brand from '../../../services/phoneBrand'
import instance_phone_model from '../../../services/phoneModel'

export default {
  components: { DatePicker },
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    st: '',
    selected: [],
    serverOptions: {
      page: 1,
      itemsPerPage: 15,
    },
    total: 0,
    count: 10,
    size: 40,
    products: [],
    product_types: [],
    phone_brands: [],
    phone_models: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    type: 'online',
    headers: [
      {
        text: 'เลือก',
        value: 'select',
        sortable: false,
      },
      {
        text: 'ลำดับ',
        value: 'index',
        sortable: false,
      },
      { text: 'รูป/ภาพ', value: 'image', sortable: false },
      { text: 'รหัสสินค้า', value: 'product_code' },
      { text: 'ชื่อ', value: 'product_name' },
      { text: 'ยี่ห้อ', value: 'brand.phone_brand_name', sortable: false },
      { text: 'รุ่น', value: 'phone_model.phone_model_name', sortable: false },
      { text: 'จำนวนคงเหลือ/ชิ้น', value: 'p_amount' },
      { text: 'สถานะ (เปิด/ปิด)', value: 'status' },
      { text: 'ดูรายการปรับยอดสินค้า', value: 'view', sortable: false },
      { text: 'แก้ไขข้อมูล', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      duplicate: '0',
      image_path: '',
      product_code: '',
      product_name: '',
      phone_brand_name: '',
      phone_model_name: '',
      product_amount: 0,
      product_type_id: '',
      phone_brand_id: '',
      phone_model_id: '',
      type_product: '1',
      product_costs: '',
      product_weight: '',
      product_detail: '',
      file: null,
      product_price1: '',
      product_price2: '',
      product_price3: '',
      amount: 0,
    },
    defaultItem: {
      duplicate: '0',
      image_path: '',
      product_code: '',
      product_name: '',
      phone_brand_name: '',
      phone_model_name: '',
      product_amount: 0,
      product_type_id: '',
      phone_brand_id: '',
      phone_model_id: '',
      type_product: '1',
      product_costs: '',
      product_weight: '',
      product_detail: '',
      file: null,
      product_price1: '',
      product_price2: '',
      product_price3: '',
      amount: 0,
    },
  }),
  validations() {
    return {
      editedItem: {
        product_costs: { required },
        product_name: { required },
        product_type_id: { required },
        product_weight: { required },
        phone_brand_id: { required },
        phone_model_id: { required },
      },
    }
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม สินค้า' : 'แก้ไข สินค้า'
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getProduct()
      },
    },
    search: {
      handler() {
        this.getProduct()
      },
    },

    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getProduct()
    this.getProductType()
    this.getPhoneBrand()
    this.getPhoneModel()
  },

  methods: {
    row_classes(item) {
      if (item.p_amount == 0) {
        return 'tr-danger'
      }

      return 'tr'
    },
    printQR() {
      let url = this.$router.resolve({ name: 'print_qr' }).href
      url += `?product_code=${this.editedItem.product_code}&size=${this.size}&count=${this.count}`
      window.open(url, 'noreferrer')
    },
    editItem(item) {
      this.v$.$reset()
      instance_product.show(item.product_id).then(res => {
        this.editedIndex = this.products.indexOf(item)
        this.editedItem = { ...item }
        this.dialog = true
        if (res.data.product_price[0]) {
          this.editedItem.product_price1 = res.data.product_price[0].product_price
        }

        if (res.data.product_price[1]) {
          this.editedItem.product_price2 = res.data.product_price[1].product_price
        }
        if (res.data.product_price[2]) {
          this.editedItem.product_price3 = res.data.product_price[2].product_price
        }

        this.editedItem.type_product = `${res.data.data.type_product}`
      }).catch(err => {

      })
    },
    deleteSelected() {
      this.$store.state.isLoading = true
      instance_product.destroySelect(this.selected).then(res => {
        setTimeout(() => {
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          })
        }, 150)
        this.getProduct()
        this.closeDelete()
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false
      })
    },
    deleteItem(item) {
      this.editedIndex = this.products.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },
    UpdateStatus(id) {
      this.$store.state.isLoading = true
      instance_product.updateStatus(id).then(res => {
        Swal.fire({
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500,
        })
        this.$store.state.isLoading = false
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false
      })
    },
    deleteItemConfirm() {
      this.$store.state.isLoading = true
      instance_product.destroy(this.editedItem.product_id).then(res => {
        setTimeout(() => {
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          })
        }, 150)
        this.getProduct()
        this.closeDelete()
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false
      })
    },

    close() {
      this.v$.$reset()
      this.dialog = false

      // this.$nextTick(() => {
      this.editedItem = { ...this.defaultItem }
      this.editedIndex = -1

      // })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },
    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.$store.state.isLoading = true

      if (this.editedIndex > -1) {
        instance_product.update(this.editedItem)
          .then(res => {
            setTimeout(() => {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              })
            })
            this.getProduct()

            this.close()
          }).catch(err => {
            Swal.fire({
              icon: 'error',
              title: 'เกิดข้อผิดพลาด !!',
              text: err.message,
            })
            this.$store.state.isLoading = false
          })
      } else {
        instance_product.store(this.editedItem)
          .then(res => {
            setTimeout(() => {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              })
            })
            this.getProduct()
            this.close()
          }).catch(err => {
            Swal.fire({
              icon: 'error',
              title: 'เกิดข้อผิดพลาด !!',
              text: err.message,
            })
            this.$store.state.isLoading = false
          })
      }
    },
    getProduct() {
      this.$store.state.isLoading = true
      const data = {
        search: this.search,
        type: this.type,
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
        date: this.$route.query.date ? this.$route.query.date : '',
        status: this.$route.query.status ? this.$route.query.status : '',
        sortBy: this.sortBy ?? '',
        sortDesc: this.sortDesc ?? '',
      }

      instance_product.get(data).then(res => {
        this.products = res.data.data.data
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    getProductType() {
      this.$store.state.isLoading = true

      instance_product_type.all().then(res => {
        this.product_types = res.data.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    getPhoneBrand() {
      this.$store.state.isLoading = true

      instance_phone_brand.all().then(res => {
        this.phone_brands = res.data.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    getPhoneModel() {
      this.$store.state.isLoading = true

      const data = {
        search: this.st ? this.st : '',
      }

      instance_phone_model.all(data).then(res => {
        this.phone_models = res.data.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
  },
}
</script>

<style>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.tr-danger {
  background-color: #f86c6b !important;
  color: black !important;
}

.tr-danger td{

  color: black !important;
}

.tr-success{
  background-color:  rgb(147, 196, 125)
}
</style>
